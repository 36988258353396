import { graphql } from 'gatsby';
import get from 'lodash/get';
import React from 'react';

// Styles
import {
  SubscribeFormSection,
  SubscribeFormTitle,
} from 'components/Subscribe/local-styles';

// Layouts
import { SEOContent } from 'layouts';

// Templates
import { MarkdownTemplate } from 'templates';

// Types
interface Props {
  data: {
    allAirtable: {
      sections: Array<{
        data: object;
        id: string;
      }>;
    };
  };
}

const CampershipPageTemplate = ({ data }: Props) => {
  const sections = get(data, 'allAirtable.sections', []) || [];
  const formUrl = get(sections, '[0].data.form_url', '') || '';

  return (
    <SEOContent title="Campership">
      <MarkdownTemplate postTitle="Campership" sections={sections}>
        {formUrl && (
          <SubscribeFormSection>
            <h3 css={SubscribeFormTitle}>
              <a href={formUrl} target="_blank" rel="noopener noreferrer">
                Apply for this campership
              </a>
            </h3>
          </SubscribeFormSection>
        )}
      </MarkdownTemplate>
    </SEOContent>
  );
};

export default CampershipPageTemplate;

export const query = graphql`
  query($campershipSlug: String) {
    allAirtable(
      filter: {
        table: { eq: "Camperships" }
        data: { campership_slug: { eq: $campershipSlug } }
      }
    ) {
      sections: nodes {
        id
        data {
          markdown_content
          section_title
          form_url
          attachments {
            id
            localFiles {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`;
